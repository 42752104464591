<template>
  <v-container fluid :class="{ 'pa-6': $vuetify.breakpoint.mdAndUp }">
    <v-row no-gutters align="center">
      <v-col cols="12" md="6" align="start">
        <h2>
          <v-icon color="primary" large left>{{ icon }}</v-icon>
          {{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2>
      </v-col>
      <v-col cols="12" md="6" align="end">
        <v-btn
          class="primary--text mx-2"
          @click="exportFile"
          :loading="exportLoading"
          >Export
          <v-icon right color="primary">mdi-download-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="userCan('productivity-import')"
          class="primary--text"
          :to="{ name: 'create-' + [routeName] }"
          >Add new
          <v-icon right color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-toolbar
      :prominent="$vuetify.breakpoint.smAndDown ? true : false"
      dark
      color="primary"
      class="mt-4"
    >
      <v-row
        :no-gutters="$vuetify.breakpoint.smAndDown ? true : false"
        align="center"
      >
        <v-col cols="6" sm="6" md="4" class="pa-2">
          <v-text-field
            v-model="pagination.search"
            clearable
            flat
            solo-inverted
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            placeholder="Search"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="3" class="pa-2">
          <v-menu
            v-model="contractMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateValueFrom"
                label="Date from"
                hide-details
                outlined
                dense
                readonly
                class="font-weight-bold text-subtitle-1"
                clearable
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateValueFrom"
              type="month"
              @input="contractMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="6" md="3" class="pa-2">
          <v-menu
            v-model="contractMenu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                v-model="dateValueTo"
                dense
                label="Date to"
                readonly
                clearable
                outlined
                v-bind="attrs"
                prepend-inner-icon="mdi-calendar"
                class="font-weight-bold text-subtitle-1"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateValueTo"
              type="month"
              @input="contractMenu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="6" md="2" class="pa-2">
          <v-btn depressed outlined dark @click="applyFilters">
            <v-icon left v-if="$vuetify.breakpoint.mdAndUp"
              >mdi-filter-variant</v-icon
            >
            Apply Filters
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table
      v-model="selected"
      class="cursor-pointer"
      single-select
      :items="items"
      :options="pagination"
      :search="pagination.search"
      :items-per-page.sync="pagination.rowsPerPage"
      :server-items-length.sync="totalCount"
      :page.sync="pagination.page"
      :headers="names"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions,
      }"
    >
      <template v-slot:item="{ item, isSelected, select }">
        <tr
          :class="isSelected ? 'green lighten-4' : ''"
          @click="showDetails(isSelected, select, item)"
          style="cursor: pointer"
        >
          <td>
            <v-icon class="">mdi-text-box-search-outline</v-icon>
          </td>
          <td class="font-weight-bold">{{ formDateName(item.for_month) }}</td>
          <td>{{ item.vacation_days_used }}</td>
          <td>{{ item.neto_salary_sum.toFixed(2) }}</td>
          <td>{{ item.gross_salary_sum.toFixed(2) }}</td>
          <td>{{ item.total_cost_sum.toFixed(2) }}</td>
        </tr>
      </template>
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense>
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar>
          <v-alert color="green lighten-1" elevation="2" type="info">
            No data available
          </v-alert>
        </v-card>
      </template>
      >
    </v-data-table>
    <v-row align="center">
      <v-col cols="12" md="4" align="start">
        <h2>
          <v-icon color="primary" large class="mr-0" left>{{
            detailsIcon
          }}</v-icon>
          {{ detailsTitle }}
          <span v-if="detailsTotal"
            >for {{ formatDate(selectedItem.for_month) }} ({{
              detailsTotal
            }})</span
          >
        </h2>
      </v-col>
      <v-col cols="12" md="6" v-if="details.length">
        <v-select
          v-model="selectedHeaders"
          :items="detailsHeader"
          label="Select Columns"
          multiple
          outlined
          return-object
          hide-details
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="selectedHeaders.length > 0 ? 'primary' : ''"
                >
                  {{ selectAllIcon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 3">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 3" class="grey--text caption"
              >(+{{ selectedHeaders.length - 3 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="2" class="text-right" v-if="details.length">
        <v-btn
          class="primary--text"
          @click="detailsExport"
          :loading="exportLoading"
          >Export
          <v-icon right color="primary">mdi-download-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-toolbar
      dark
      color="primary"
      class="mt-4"
      :class="filterSelected ? 'mb-0' : 'mb-3'"
      v-if="details.length"
    >
      <v-col cols="6" sm="6" md="4" class="pa-2">
        <v-text-field
          v-model="paginationDetails.search"
          clearable
          flat
          dense
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Search"
          placeholder="Search"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="text-left pa-2" md="2">
        <v-btn
          depressed
          outlined
          dark
          @click="filterSelected = !filterSelected"
        >
          <v-icon left v-if="$vuetify.breakpoint.mdAndUp"
            >mdi-filter-variant</v-icon
          >
          {{ filterSelected ? "Close Filters" : "Filters" }}
        </v-btn>
      </v-col>
    </v-toolbar>
    <filter-wrapper
      v-if="filterSelected"
      color="green lighten-1"
      :filters.sync="filters"
      :model.sync="paginationFilter"
      :loading.sync="loading"
      v-on:applyFilters="applyFiltersDetails"
      v-on:resetFilters="resetFilters"
    ></filter-wrapper>

    <v-data-table
      id="details"
      :items="details"
      :options="paginationDetails"
      :search="paginationDetails.search"
      :items-per-page.sync="paginationDetails.rowsPerPage"
      :server-items-length.sync="detailsTotal"
      :page.sync="paginationDetails.page"
      :loading="loading"
      :headers="showHeaders"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptionsDetails,
      }"
    >
      <template v-slot:item.neto_salary="{ item }">
        {{ item.neto_salary.toFixed(2) }}
      </template>
      <template v-slot:item.gross_salary="{ item }">
        {{ item.gross_salary.toFixed(2) }}
      </template>
      <template v-slot:item.total_cost="{ item }">
        {{ item.total_cost.toFixed(2) }}
      </template>
      <template v-slot:item.first_contract_signed_at="{ item }">
        {{ formatDate(item.first_contract_signed_at) }}
      </template>
      <template v-slot:item.contract_updated_at="{ item }">
        {{ formatDate(item.contract_updated_at) }}
      </template>
      <template v-slot:item.contract_expired="{ item }">
        {{ formatDate(item.contract_expired) }}
      </template>
      <template
        v-slot:body.append="{ headers }"
        v-if="$vuetify.breakpoint.smAndDown ? false : true"
      >
        <tr class="green--text text-bold">
          <td v-for="(header, i) in headers" :key="i">
            <div v-if="header.value === 'vacation_days'">
              {{ sumValues("vacation_days") }}
            </div>
            <div v-if="header.value === 'vacation_days_used'">
              {{ sumValues("vacation_days_used") }}
            </div>
            <div v-if="header.value === 'vacation_days_left'">
              {{ sumValues("vacation_days_left") }}
            </div>
            <div v-if="header.value === 'sick_leave'">
              {{ sumValues("sick_leave") }}
            </div>
            <div v-if="header.value === 'overtime_hours'">
              {{ sumValues("overtime_hours") }}
            </div>
            <div class="text-right" v-if="header.value === 'neto_salary'">
              {{ sumValues("neto_salary") }}
            </div>
            <div class="text-right" v-if="header.value === 'gross_salary'">
              {{ sumValues("gross_salary") }}
            </div>
            <div class="text-right" v-if="header.value === 'total_cost'">
              {{ sumValues("total_cost") }}
            </div>
            <div v-else></div>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense>
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar>
          <v-alert color="green darken-1" elevation="2" type="info">
            No data available
          </v-alert>
        </v-card>
      </template>
      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense>
            <v-toolbar-title>Loading data...</v-toolbar-title>
          </v-toolbar>
          <v-alert colored-border color="primary" elevation="2">
            <v-progress-circular indeterminate color="primary" />
          </v-alert>
        </v-card>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import downloadExcel from "@/mixins/downloadExcel";
import string from "@/mixins/string";
import _ from "lodash";
import Vue from "vue";
import { mapGetters } from "vuex";
export default {
  mixins: [date, permission, string, downloadExcel],
  name: "TwoTables",
  components: {
    FilterWrapper: () => import("@/components/core/filters/FilterWrapper"),
  },
  props: {
    names: {
      type: Array,
      required: true,
    },
    detailsHeader: {
      type: Array,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    keyTitle: {
      type: String,
      required: true,
    },
    namespace: {
      type: String,
      required: true,
    },
    goTo: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    detailsTitle: {
      type: String,
      required: true,
      default: "Title",
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help",
    },
    detailsIcon: {
      type: String,
      required: true,
      default: "mdi-help",
    },
  },
  data() {
    return {
      exportLoading: false,
      confirmDeleteDialog: false,
      itemsPerPageOptions: [12, 24, 48, -1],
      itemsPerPageOptionsDetails: [12, 24, 48, -1],
      selected: [],
      details: [],
      detailsTotal: 0,
      selectedItem: {},
      detailsFilterSelected: false,
      menu: false,
      menu1: false,
      contractMenu: false,
      contractMenu2: false,
      dateValueFrom: null,
      dateValueTo: null,
      filterSelected: false,
      paginationFilter: {},
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      showHeaders: [],
      allProductivity: [],
    };
  },
  created() {
    this.getData();
    if (this.selectedHeaders.length == 0)
      this.$store.commit("productivity/setSelectedHeaders", this.detailsHeader);
    this.changeHeader();
  },
  computed: {
    ...mapGetters({
      filters: "productivity/filters",
      totalCount: "productivity/total",
      loading: "productivity/loading",
    }),
    paginationDetails: {
      get() {
        return this.$store.getters["productivity/detailsPagination"];
      },
      set(value) {
        this.$store.dispatch("productivity/setDetailsPagination", value);
      },
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems");
      },
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      },
    },
    selectedHeaders: {
      get() {
        return this.$store.getters["productivity/selectedHeaders"];
      },
      set(value) {
        this.$store.commit("productivity/setSelectedHeaders", value);
        this.changeHeader();
      },
    },
    selectAllColumns() {
      return this.selectedHeaders.length === this.detailsHeader.length;
    },
    selectedSomeColumns() {
      return this.selectedHeaders.length > 0 && !this.selectAllColumns;
    },
    selectAllIcon() {
      if (this.selectAllColumns) return "mdi-close-box";
      if (this.selectedSomeColumns) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  beforeDestroy() {
    Vue.set(this.pagination, "search", null);
    Vue.set(this.pagination, "report_for_date_from", null);
    Vue.set(this.pagination, "report_for_date_to", null);
    this.resetFilters();
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    paginationDetails: {
      handler() {
        this.getDetailsData();
      },
      deep: true,
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllColumns) {
          this.selectedHeaders = [];
        } else {
          this.selectedHeaders = this.detailsHeader.slice();
        }
      });
    },
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems");
    },
    changeHeader() {
      const interesct = _.intersectionBy(
        this.detailsHeader,
        this.selectedHeaders,
        "value"
      );
      this.showHeaders = interesct;
    },
    async getDetailsData() {
      await this.$store.dispatch(
        this.namespace + "/getDetails",
        this.selectedItem.id,
        this.paginationDetails
      );
      this.details = this.$store.getters["productivity/details"];
      this.detailsTotal = this.$store.getters["productivity/detailsTotal"];
    },
    saveColumns() {
      localStorage.setItem("settings", JSON.stringify(this.selectedHeaders));
    },
    sumValues(key) {
      return this.details.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2);
    },
    resetFilters() {
      Vue.set(this.paginationFilter, "jobLocations", []);
      Vue.set(this.paginationFilter, "jobPositionNames", []);
      Vue.set(this.paginationFilter, "salaryType", null);
      Vue.set(this.paginationFilter, "salaryFrom", null);
      Vue.set(this.paginationFilter, "salaryTo", null);
      Vue.set(this.paginationFilter, "contractLength", null);
      Vue.set(this.paginationFilter, "sickLeaveFrom", null);
      Vue.set(this.paginationFilter, "sickLeaveTo", null);
      Vue.set(this.paginationFilter, "overtimeHoursFrom", null);
      Vue.set(this.paginationFilter, "overtimeHoursTo", null);
      Vue.set(this.paginationFilter, "vacationType", null);
      Vue.set(this.paginationFilter, "vacationFrom", null);
      Vue.set(this.paginationFilter, "vacationTo", null);
      this.paginationDetails = {
        ...this.paginationDetails,
        ...this.paginationFilter,
      };
    },
    applyFiltersDetails() {
      this.paginationDetails = {
        ...this.paginationDetails,
        ...this.paginationFilter,
      };
    },
    goToAnchor(anchor) {
      this.$vuetify.goTo(anchor, {
        duration: 700,
        easing: "easeInOutCubic",
      });
    },
    applyFilters() {
      Vue.set(this.pagination, "report_for_date_from", this.dateValueFrom);
      Vue.set(this.pagination, "report_for_date_to", this.dateValueTo);
    },
    showDetails(isSelected, select, item) {
      this.selectedItem = item;
      select(!isSelected);

      this.goToAnchor("#details");
      this.$store.dispatch("productivity/getDetails", item.id).then(() => {
        this.details = this.$store.getters["productivity/details"];
        this.detailsTotal = this.$store.getters["productivity/detailsTotal"];
      });
    },
    async exportFile() {
      this.exportLoading = true;
      let columns = this.names.filter((element) => element.text !== "Details");
      if (!this.allProductivity.length) {
        await this.$store
          .dispatch("productivity/exportAllItemsGet")
          .then(() => {
            this.allProductivity =
              this.$store.getters["productivity/exportItems"];
          });
        this.exportToExcel(
          columns,
          this.allProductivity,
          this.namespace + ".xlsx"
        );
        this.exportLoading = false;
        this.allProductivity=[]
      }
    },
    detailsExport() {
      this.exportLoading = true;
      this.$store
        .dispatch("productivity/exportDetailsList", this.selectedItem.id)
        .then(() => {
          this.exportDetailsItems =
            this.$store.getters["productivity/exportDetailsItems"];
          this.exportToExcel(
            this.showHeaders,
            this.exportDetailsItems,
            "productivity-details.xlsx"
          );
          this.exportLoading = false;
        });
    },
  },
};
</script>
<style scoped>
>>> .theme--dark.v-label {
  color: rgb(255, 255, 255);
}
</style>
